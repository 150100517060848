<template>
  <div>
    <Nav/>
    <div class="detail-container">
      <div class="bs-flex">
        <div style="margin-left: auto">
          <button class="table-btn" @click="prevBoard">
            <img src="@/assets/images/IA/icon_prev_post.png" alt="list">
            이전글
          </button>
          <button class="table-btn" @click="nextBoard">
            <img src="@/assets/images/IA/icon_next_post.png" alt="list">
            다음글
          </button>
          <button class="table-btn" @click="refList">
            <img src="@/assets/images/IA/icon_list_view.png" alt="list">
            목록
          </button>
        </div>
      </div>
      <div class="detail-sub-container">
        <h5 v-html="qnaInfo.title" class="subTitle"></h5>
        <div class="bs-flex">
          <p v-html="qnaInfo.regDateTime" class="subDate"></p>
          <p class="subViewTitle">등록인 </p>
          <p class="subView" v-if="qnaInfo.writerInfo.writerName != null">{{ qnaInfo.writerInfo.writerName }}</p>
          <p class="subViewTitle">조회수 </p>
          <p v-html="qnaInfo.viewCount + 1" class="subView"></p>
        </div>
        <hr class="line">
        <mdb-input type="textarea" id="editor" class="textarea-desc" outline v-model="qnaInfo.desc" readOnly :rows="10"/>
      </div>
      <div class="change-btn" v-if="userUid === qnaInfo.writerInfo.writerUid">
        <button class="remove-btn" @click="isRemoveModal = true">
          <img src="@/assets/images/IA/icon_delete.png" alt="">
          삭제
        </button>
        <button class="modify-btn" @click="postEdit">
          <img src="@/assets/images/IA/icon_edit.png" alt="">
          수정
        </button>
      </div>
      <div class="reply-add-container">
        <h5>댓글달기</h5>
        <div class="reply-user">
          <img src="@/assets/images/IA/user_icon.png" alt="">
          <p>{{ $store.state.user.displayName }}</p>
        </div>
        <div class="reply-box">
          <input type="text" v-model="contents" class="custom-input-box"/>
          <button class="reply-btn" @click="addReply">
            <img src="@/assets/images/IA/icon_re.png" alt="">
            등록
          </button>
        </div>
      </div>
      <div class="reply-container">
        <QnaReply :replyInfo="replyList[i]" v-for="(a,i) in replyList" :key="i" @deleteReply="deleteReply"/>
      </div>
    </div>
    <div class="modal-cover" v-show="isRemoveModal"></div>
    <RemoveDialog @closeModal="closeModal" @removeModal="removeModal" v-if="isRemoveModal === true"/>
  </div>
</template>

<script>
import RemoveDialog from '@/views/common/RemoveDialog.vue';
import {firebase} from "@/firebase/firebaseConfig";
import {getDate, getDateTime} from "@/lib/prettyDate";
import QnaReply from "@/views/qna/QnaReply";
import Nav from "@/components/Nav";
import {mdbInput} from "mdbvue";

export default {
  name: "QnaDetailView",
  components: {Nav, QnaReply, RemoveDialog, mdbInput},
  data() {
    return {
      contents: '',
      isRemoveModal: false,
      isMine: true,
      replies: [],
      fbCollection: 'qna',
      userUid: this.$store.state.user.uid,
      postId: this.$route.params.id,
      qnaList: [],
      qnaInfo: {
        orderNo: 0,
        title: '',
        desc: '',
        regDate: '',
        viewCount: 0,
        writerInfo: {
          writerName: '',
          writerUid: '',
          writerEmail: '',
        }
      },
      currentIndex: 0,
      maxIndex: 100,
      replyList: [],
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      const self = this;
      await self.getCurrentData();
      await self.getCurrentReply();
      await self.getDataList();
    },
    async getCurrentData() {
      const self = this;
      const db = firebase.firestore();
      await db.collection(self.fbCollection)
          .doc(self.postId)
          .get()
          .then(async (snapshot) => {
            if (!snapshot.exists) {
              alert('존재하지 않는 게시글 입니다.');
              await self.$router.replace('/qna');
              return;
            }
            const _data = snapshot.data();
            const date = new Date(_data.regDate.seconds * 1000);
            _data.regDate = getDate(date);
            _data["regDateTime"] = getDateTime(date);
            self.qnaInfo = _data;
          })
      await self.addViewCount();
    },
    async getCurrentReply() {
      const self = this;
      self.replyList.splice(0);
      const db = firebase.firestore();
      await db.collection(self.fbCollection)
          .doc(self.postId)
          .collection('reply')
          .orderBy('regDate', 'desc')
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.size === 0) {
              return;
            }
            querySnapshot.forEach((doc) => {
              const _data = doc.data();
              _data['docId'] = doc.id;
              const date = new Date(_data.regDate.seconds * 1000);
              _data.regDate = getDate(date);
              _data["regDateTime"] = getDateTime(date);
              self.replyList.push(_data);
            });
          });
      self.maxIndex = self.qnaList.length;
      self.currentIndex = self.qnaList.findIndex((element) => element.docId === self.postId);
    },
    addViewCount() {
      const self = this;
      const db = firebase.firestore();
      db.collection(self.fbCollection)
          .doc(self.postId)
          .update({viewCount: firebase.firestore.FieldValue.increment(+1)},);
    },
    async getDataList() {
      const self = this;
      self.qnaList.splice(0);
      const db = firebase.firestore();
      await db.collection(self.fbCollection)
          .orderBy('orderNo', 'asc')
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.size === 0) {
              return;
            }
            querySnapshot.forEach((doc) => {
              const _data = doc.data();
              _data['docId'] = doc.id;
              self.qnaList.push(_data);
            });
          });
      self.maxIndex = self.qnaList.length;
      self.currentIndex = self.qnaList.findIndex((element) => element.docId === self.postId);
    },
    async prevBoard() {
      const self = this;
      if (self.currentIndex === 0) {
        alert('마지막 글입니다.');
        return;
      }
      const value = self.qnaList[self.currentIndex - 1];
      await self.$router.replace({name: 'qnaDetail', params: {id: value.docId}})
      self.$router.go();
    },

    async nextBoard() {
      const self = this;
      if (self.maxIndex === self.currentIndex + 1) {
        alert('마지막 글입니다.');
        return;
      }
      const value = self.qnaList[self.currentIndex + 1];
      await self.$router.replace({name: 'qnaDetail', params: {id: value.docId}})
      self.$router.go();
    },
    closeModal() {
      const self = this;
      self.isRemoveModal = false;
    },
    async removeModal() {
      const self = this;
      const db = firebase.firestore();
      await db.collection(self.fbCollection)
          .doc(self.postId)
          .delete()
          .then(async () => {
            await self.refList();
          })
    },
    async refList() {
      const self = this;
      await self.$router.replace('/qna');
    },
    postEdit() {
      const self = this;
      self.$router.push(`/qna/${self.postId}/edit`)
    },
    addReply() {
      const self = this;

      if (self.contents.trim().length === 0) {
        alert('작성된 댓글이 없습니다.');
        return
      }

      const db = firebase.firestore();
      const _data = {
        contents: self.contents,
        isDeleted: false,
        regDate: firebase.firestore.Timestamp.fromDate(new Date()),
        writerInfo: {
          writerName: this.$store.state.user.displayName,
          writerUid: this.$store.state.user.uid,
          writerEmail: this.$store.state.user.email,
        }
      }
      const docRef = db.collection(self.fbCollection)
          .doc(self.postId)
          .collection('reply')
          .doc();
      docRef.set(_data)
          .then(() => {
            self.contents = '';
            const dd = {..._data}
            dd.docId = docRef.id;
            dd.regDateTime = '방금전'
            self.replyList.unshift(dd)

          });
    },
    deleteReply() {
      const self = this;
      self.getCurrentReply();
    },
  }
}
</script>

<style scoped>

.bs-flex {
  display: flex;
}

.bs-flex img {
  height: 18px;
  width: 18px;
}

.table-btn {
  background: #EDEDF1;
  border-radius: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #000000;
  border: 0;
  margin-left: 10px;
  padding: 2px 10px;
}

.table-btn img {
  width: 18px;
  height: 18px;
  transform: translateY(-2px);
}

.detail-container {
  width: 1110px;
  margin: 0 auto;
  padding: 40px 0 80px;
  min-height: 565px;
}

.detail-sub-container {
  margin-top: 20px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
}

.subTitle {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.0025em;
  color: #272833;
  margin-bottom: 5px;
  margin-top: 23px;
  margin-left: 32px;
}

.subDate {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.001em;
  color: #8D8D8D;
  margin-left: 32px;
  margin-bottom: 23px;

}

.subViewTitle {
  margin: 0 8px 0 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.001em;
  color: #8D8D8D;
}

.subView {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.001em;
  color: #8D8D8D;
}

.line {
  width: 100%;
  margin: 0;
}


.change-btn {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
}

.change-btn button {
  height: 40px;
  width: 70px;
  border: 0;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  margin-left: 5px;
}

.change-btn button img {
  height: 18px;
  width: 18px;
  margin-right: 4px;
}

.change-btn .remove-btn {
  background: #D23442;
}

.change-btn .modify-btn {
  background: #5AC820;
}


.reply-add-container {
  background: #FAFAFA;
  margin-top: 40px;
  height: 148px;
  padding: 20px;
  border: #E1E3E6 1px solid;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 #E7E7ED;
  margin-bottom: 20px;
}

.reply-add-container h5 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #8D8D8D;
  margin: 0 0 10px 5px;

}

.reply-add-container .reply-user {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 10px;
}

.reply-add-container .reply-user img {
  height: 18px;
  width: 18px;
  margin: 0 5px;
}

.reply-add-container .reply-box {
  display: flex;
  height: 40px;
}

.reply-add-container .reply-box input {
  outline: 0;
}

.reply-add-container .custom-input-box {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
}

.reply-add-container .reply-btn {
  color: #ffffff;
  background: #20173C;
  border-radius: 4px;
  border: 0;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;

}

.reply-add-container .reply-btn img {
  width: 18px;
  height: 18px;
  margin-right: 4px;
}

.detail-container .reply-container {
  margin-top: 20px;
}

/deep/ .textarea-desc {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

/deep/ .textarea-desc textarea {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.0025em;
  color: #272833;
  border: 0 !important;
  resize: none;
  padding: 23px 32px !important;
  margin: 0;
  overflow: overlay;
  height: 552px;
}
</style>
